<template>
  <!-- Navbar -->
  <nav
    id="navbar-main"
    class="navbar navbar-horizontal navbar-main navbar-expand-lg navbar-light"
  >
    <div class="container">
      <router-link to="/">
             <img src="@/assets/images/logo2.jpeg" width="70" height="70"  class="rounded" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-collapse"
        aria-controls="navbar-collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        v-if="$route.path !== '/entities/registration'"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse navbar-custom-collapse collapse"
        id="navbar-collapse"
      >
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="/">
                   <img src="@/assets/images/logo2.jpeg" width="50" height="50"  class="rounded" />
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                class="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar-collapse"
                aria-controls="navbar-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <ul
          v-if="$route.path !== '/entities/registration'"
          class="navbar-nav ml-auto"
        >
          <li class="nav-item mr-2 mt-2"  v-if="$route.path !== '/login' && !$store.getters.isLoggedIn">
            <router-link  class="btn btn-outline-primary" :to="{ name: 'Login' }" >
                  {{$t('login')}}
            </router-link>
          </li>
          <li class="nav-item mr-2 mt-2">
            <router-link class="btn btn-outline-primary" :to="register_router">
                  {{$t('add_product')}}
            </router-link>
          </li>
          <li>
           <select v-model="$i18n.locale" class="form-control  mr-2 mt-2">
              <option v-for="(lang, i) in langs" :key="`Lang${i.key}`" :value="lang.val">{{ lang.key }}</option>
            </select>
         </li>
       
        </ul>
      </div>
    </div>
  </nav>
  <!-- Main content -->
  <div class="main-content">
    <!-- Header -->
    <div class="header  pt-3 pb-6 pt-lg-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5 mt-4">
             
              <h1
                v-if="$route.path === '/entities/registration'"
                class="text-white"
              >
                Register here!
              </h1>
              <p
                v-if="$route.path === '/entities/registration'"
                class="text-white-50"
              >
                Please fill this form!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator h-100vh separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <router-view v-slot="{ Component }">
          <transition name="slide-fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
  <Footer v-if="false"></Footer>
</template>

<script>
import Footer from "../components/footer";
import Sponsor from "../components/Sponsor";
export default {
  components: {
    Footer,
    Sponsor,
  },
  data(){
    return{
       langs: [{'key':'En','val':'en'}, {'key':'Kin','val':'kin'}]
    }
  },
  computed:{
     register_router:function(){
       if (this.$store.getters.isLoggedIn){
         return { name: 'New Product' }
       }else{
         return { name: 'Login' }
       }
    },
  },
  created() {
    document.body.classList.add("bg-white");
  },
};
</script>

<style>
</style>