<template>
  <div>
    <div class="row justify-content-center align-items-center">
      <div class="col-md-4"></div>
      <div class="col-md-2 col-sm-4 col-2">
        <h1>
          BCM
        </h1>
      </div>
      <div class="col-md-4"></div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="css" scoped>
    .col-md-2{
        margin:auto;
    }
</style>
